"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInvalidArray = exports.isInvalidString = exports.isNullorUndefined = exports.generatePrimaryKey = void 0;
function generatePrimaryKey(accountid, tenantid) {
    return `${accountid}-${tenantid}`;
}
exports.generatePrimaryKey = generatePrimaryKey;
/**
 * The reason this exists (instead of a nullish coalescing operator ??)
 * is because there are cases where we want to explictly check fo undefined or null and
 * not the overal truthiness of the variable
 */
function isNullorUndefined(value) {
    return value === null || value === undefined;
}
exports.isNullorUndefined = isNullorUndefined;
function isInvalidString(value) {
    if (!value || value.length < 3)
        return true;
    return false;
}
exports.isInvalidString = isInvalidString;
function isInvalidArray(value) {
    if (!value || !value.length)
        return true;
    return false;
}
exports.isInvalidArray = isInvalidArray;

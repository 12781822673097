"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateCombinations = exports.backtrack = exports.getYield = void 0;
const types_1 = require("./types");
const utils_1 = require("./utils");
const MIN_BREAK_INVESTMENT = 100;
/**
 *
 * @param combination
 * @param totalValue
 * @returns
 *
 * This function computes the net dividend yeild of a given combination of stocks
 */
function getYield(combination, valueToCompare) {
    if (!valueToCompare || (0, utils_1.isInvalidArray)(combination)) {
        console.log(`invalid arguments provided...`);
        return 0;
    }
    const totalDividends = combination.reduce((total, stock) => total + stock.dividendYield * stock.price * stock.count, 0);
    return totalDividends / valueToCompare;
}
exports.getYield = getYield;
/**
 *
 * @param remainingInvestment
 * @param combination
 * @param startIndex
 * @returns
 *
 * This function is brute force backtracking recursive function.
 */
function backtrack(remainingInvestment, startingInvestment, targetYield, stocks, combination, startIndex, combinations) {
    //Exit conditions - no more money to invest
    if (remainingInvestment < 0 || startingInvestment < 1) {
        return;
    }
    //Save combination conditions
    if (getYield(combination, startingInvestment) >= targetYield &&
        Math.abs(remainingInvestment) < MIN_BREAK_INVESTMENT) {
        let valid = true;
        combination.forEach((stock) => {
            if (stock.count === 0) {
                valid = false;
            }
        });
        if (!valid) {
            return;
        }
        combinations.push(Object.assign({}, combination));
        return;
    }
    for (let i = startIndex; i < stocks.length; i++) {
        const stock = stocks[i];
        const maxShares = Math.floor(remainingInvestment / stock.price);
        for (let shares = 0; shares <= maxShares; shares++) {
            const investmentAmount = shares * stock.price;
            if (remainingInvestment >= investmentAmount) {
                //Lock & Backtrack on that branch
                const stockCopy = new types_1.stockImpl(stock);
                stockCopy.count = shares;
                combination.push(stockCopy);
                backtrack(remainingInvestment - investmentAmount, startingInvestment, targetYield, stocks, combination, i + 1, combinations);
                combination.pop();
            }
        }
    }
}
exports.backtrack = backtrack;
function generateCombinations(stocks, TotalPrincipal, targetYield) {
    const combinations = [];
    backtrack(TotalPrincipal, TotalPrincipal, targetYield, stocks, [], 0, combinations);
    return combinations;
}
exports.generateCombinations = generateCombinations;

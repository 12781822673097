"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stockImpl = void 0;
const utils_1 = require("./utils");
class stockImpl {
    constructor(obj) {
        this.name = "";
        this.ticker = "";
        this.dividendYield = 0;
        this.price = 0;
        this.count = 0;
        if (obj) {
            this.name = obj.name;
            this.ticker = obj.ticker;
            this.price = obj.price;
            this.dividendYield = obj.dividendYield;
        }
    }
    isValid() {
        if ((0, utils_1.isInvalidString)(this.name) ||
            (0, utils_1.isInvalidString)(this.ticker) ||
            !this.price) {
            return false;
        }
        return true;
    }
}
exports.stockImpl = stockImpl;

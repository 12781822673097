import { useEffect } from "react";
import { Stock, stockImpl, generateCombinations, getYield } from "mystocklib";

export function App() {
  useEffect(() => {
    //
    // Retreive the todo list from the backend.
    //
  });

  async function test() {
    const stocks: Stock[] = [
      new stockImpl({
        name: "AAPL",
        ticker: "AAPL",
        dividendYield: 0.021,
        price: 150,
        count: 0,
      } as Stock), // Apple Inc.
      new stockImpl({
        name: "MSFT",
        ticker: "MSFT",
        dividendYield: 0.021,
        price: 300,
        count: 0,
      } as Stock),
      new stockImpl({
        name: "JNJ",
        ticker: "JNJ",
        dividendYield: 0.026,
        price: 120,
        count: 0,
      } as Stock),
      new stockImpl({
        name: "GOOGL",
        ticker: "GOOGL",
        dividendYield: 0.03,
        price: 2500,
        count: 0,
      } as Stock),
      new stockImpl({
        name: "V",
        ticker: "V",
        dividendYield: 0.03,
        price: 200,
        count: 0,
      } as Stock),
    ];

    const totalInvestment = 10000; // $100,000
    const targetYield = 0.02; // 3%

    const combinations = generateCombinations(
      stocks,
      totalInvestment,
      targetYield
    );

    console.log(JSON.stringify(combinations));
    combinations.forEach((combination: Stock[], index: number) => {
      const combinationVal = Object.values(combination);
      console.log(JSON.stringify(combinationVal));
      const portfolioYield = getYield(combinationVal, totalInvestment);
      if (combinationVal) {
        console.log(
          `Combination ${index + 1}:`,
          combinationVal.map((stock) => stock.name)
        );
      }
      console.log(`Portfolio Yield: ${portfolioYield * 100}%`);

      let totalInvested = 0;
      combinationVal.forEach((stock) => {
        const investedAmount = stock.count * stock.price;
        totalInvested += investedAmount;
        console.log(`- ${stock.name}: $${investedAmount}`);
      });

      console.log(`Total Invested: $${totalInvested}`);
      console.log();
    });
  }

  return (
    <div>
      <div>
        <button onClick={test}>Test</button>
      </div>
    </div>
  );
}
